import { createSlice } from '@reduxjs/toolkit'

export const modalSlice = createSlice({
    name: 'modal',
    initialState: {
        title: "",  // current  title state management
        isOpen : false,   // modal state management for opening closing
        bodyType : "",   // modal content management
        size : "",   // modal content management
        extraObject : {}, 
        defaultType:"",
        icon : {},  
        previewImageIndex: "",
        OpenTweetModal: "",
        openFeedbackModal: "",
        previewImageIndexMobile: "",
        mobile : "",
        index:"",
        gallery :"",
        createimage:"",
        time: "",
        explaination: "",
        openGallery: "",
        trialUser: "",
        promptdata: "",
        image: "",
        openYourClones: "",
        queueNo: "",
        previewInstaImageIndex : "",
        adModesYourClones:"",
        confirmation: "",
        openAdImages: "",
        upscalers: "",
        openGalleryImages:"",
        virtualClothingYourClones:"",
    },
    reducers: {

        openModal: (state, action) => {
            const {title, bodyType, extraObject, size ,defaultType, icon ,previewImageIndex, OpenTweetModal, openFeedbackModal , previewImageIndexMobile, mobile, index, gallery ,createimage, time, explaination,openGallery, trialUser, promptdata, image, openYourClones, queueNo, previewInstaImageIndex, adModesYourClones, confirmation, openAdImages, upscalers, openGalleryImages, virtualClothingYourClones} = action.payload
            state.isOpen = true
            state.bodyType = bodyType
            state.title = title
            state.size = size || 'md'
            state.extraObject = extraObject
            state.icon = icon
            state.previewImageIndex = previewImageIndex
            state.OpenTweetModal = OpenTweetModal
            state.openFeedbackModal = openFeedbackModal
            state.previewImageIndexMobile = previewImageIndexMobile
            state.mobile = mobile
            state.index = index
            state.gallery = gallery
            state.createimage = createimage
            state.time = time
            state.explaination = explaination
            state.openGallery = openGallery
            state.trialUser = trialUser
            state.promptdata = promptdata
            state.image = image
            state.openYourClones = openYourClones
            state.queueNo = queueNo
            state.previewInstaImageIndex = previewInstaImageIndex
            state.adModesYourClones = adModesYourClones
            state.confirmation = confirmation
            state.openAdImages = openAdImages
            state.upscalers = upscalers
            state.openGalleryImages = openGalleryImages
            state.virtualClothingYourClones = virtualClothingYourClones
            state.defaultType = defaultType


        },

        closeModal: (state, action) => {
            state.isOpen = false
            state.bodyType = ""
            state.title = ""
            state.extraObject = {}
            state.icon = ""
            state.previewImageIndex = ""
            state.OpenTweetModal = ""
            state.openFeedbackModal = ""
            state.previewImageIndexMobile = ""
            state.mobile = ""
            state.index = ""
            state.gallery = ""
            state.createimage = ""
            // state.openProcessVideo = ""
            state.time = ""
            state.explaination = ""
            state.openGallery = ""
            state.trialUser = ""
            state.promptdata = ""
            state.image = ""
            state.openYourClones = ""
            state.queueNo = ""
            state.previewInstaImageIndex = ""
            state.adModesYourClones = ""
            state.confirmation = ""
            state.openAdImages = ""
            state.upscalers = ""
            state.openGalleryImages = ""
            state.virtualClothingYourClones = ""
            state.defaultType = ""

        },

    }
})

export const { openModal, closeModal } = modalSlice.actions

export default modalSlice.reducer